import DetailPanel, { ISubMenuTabs } from "tools/components/DetailPanel";
import { IFeatureModel, PortfolioTransactionModel, PortfolioTransactionSummaryModel } from "proxy/apiProxy";
import { Formik, FormikHelpers, FormikProps } from 'formik';
import PortfolioTransactionData from "./PortfolioTransactionData";
import { useReduxActions, useReduxSelections } from "tools/lib/reduxStoreAccess";
import DetailPanelLoading from "tools/components/DetailPanelLoading";
import WriteAccess from "tools/fieldComponents/WriteAccess";
import { getPortfolioTransactionTypeLabel } from "./getPortfolioTransactionTypeLabel";
import { useScreenParams } from "tools/routing/screenRouteHooks";
import { useEffect } from "react";
import { isNumber, useNumber } from "../../tools/lib/utility";
import { ExtendedDocumentsGrid, RelatedType } from "features/Document/DocumentsScreen/ExtendedDocumentsGrid";

export const portfolioTransactionScreen = {
    route: "/portfoliodata/portfoliotransactions/:id/:tab?" as const,
    component: PortfolioTransaction,
    label: "Portfolio Transaction",
    tabs: {
        detail: "detail",
        documents: "documents"
    },
}

function PortfolioTransaction() {
    const {
        transactionSave,
        transactionAddSecurityInDictionary,
        transactionAddRelationshipInDictionary: transactionAddCounterpartyRelationshipInDictionary,
        transactionAddPortfolioInDictionary,
        transactionDelete,
        transactionLoad,
        transactionDocumentsLoad
    } = useReduxActions("portfolioTransaction");
    const {
        transactionCurrent,
        transactionLoading,
        transactionSaving,
        dictionaries,
        documentsLoading,
        documents
    } = useReduxSelections("portfolioTransaction");
    const { referenceCurrencies = {} } = useReduxSelections("reference");
    const { classificationTypes } = useReduxSelections("portfolioTransactionClassificationType");
    const { parameters: { portfolioTransactionExtensionFields = [] } } = useReduxSelections("parameters");

    const { id, tab: tabValue = "detail" } = useScreenParams<typeof portfolioTransactionScreen>();
    const idNum = useNumber(id);

    useEffect(() => {
        if (isNumber(idNum) && idNum) {
            transactionLoad(idNum)
        } else {
            // FIXME Add type checking
            transactionLoad(id as (PortfolioTransactionModel | PortfolioTransactionSummaryModel)["type"])
        }
    }, [id, idNum, transactionLoad]);

    useEffect(() => {
        if (idNum && tabValue === "documents" && !documentsLoading && !documents)
            transactionDocumentsLoad(idNum)
    }, [idNum, transactionDocumentsLoad, documents, documentsLoading, tabValue]);

    if (!transactionCurrent) {
        return <DetailPanelLoading />;
    }

    const handleSubmit = (values: PortfolioTransactionModel, { setSubmitting }: FormikHelpers<PortfolioTransactionModel>) => {
        transactionSave(values);
        setSubmitting(false);
    }
    const handleDelete = () => {
        if (transactionCurrent?.id) {
            transactionDelete(transactionCurrent.id);
        }
    }

    return <Formik onSubmit={handleSubmit} initialValues={transactionCurrent} enableReinitialize={true}
        validateOnMount={true}>{renderForm}</Formik>;

    function renderForm(formikProps: FormikProps<PortfolioTransactionModel>) {
        if (!transactionCurrent) {
            return;
        }
        const { dirty, isValid, submitForm, values } = formikProps;
        const { transactionCode } = values;

        const tabs: ISubMenuTabs<typeof portfolioTransactionScreen>[] = [{
            label: "Data",
            value: "detail"
        }];

        if (values?.id) {
            tabs.push({
                label: "Documents",
                value: "documents"
            });
        }

        return <WriteAccess
            value={{ feature: IFeatureModel.ManagedPortfolioWrite, portfolioId: transactionCurrent?.portfolioId ?? 0 }}>
            <DetailPanel
                isQuerying={transactionLoading || transactionSaving || documentsLoading}
                title={`Portfolio Transaction ${transactionCode}`}
                subTitle={getPortfolioTransactionTypeLabel(values.type)}
                canSave={dirty && isValid}
                onSaveClick={submitForm}
                canDelete={!!values.id}
                saveAllowed={{
                    feature: IFeatureModel.ManagedPortfolioWrite,
                    portfolioId: transactionCurrent?.portfolioId ?? 0
                }}
                deleteAllowed={{
                    feature: IFeatureModel.ManagedPortfolioWrite,
                    portfolioId: transactionCurrent?.portfolioId ?? 0
                }}
                onDeleteClick={handleDelete}
                tabs={tabs}
                tabValue={tabValue}>
                {(tabValue === "detail") && <PortfolioTransactionData
                    currencies={referenceCurrencies}
                    classificationTypes={classificationTypes}
                    portfolioTransactionExtensionFields={portfolioTransactionExtensionFields}
                    transaction={values}
                    dictionaries={dictionaries}
                    onSecurityLoaded={transactionAddSecurityInDictionary}
                    onCounterpartyRelationshipLoaded={transactionAddCounterpartyRelationshipInDictionary}
                    onPortfolioLoaded={transactionAddPortfolioInDictionary}
                />}
                {(tabValue === "documents") && <ExtendedDocumentsGrid
                    documents={documents ?? []}
                    dictionaries={dictionaries}
                    relatedType={RelatedType.Transaction}
                    relatedId={values.id} />}
            </DetailPanel>
        </WriteAccess>
    }
}
