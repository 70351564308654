import DetailPanel, { ISubMenuTabs } from "tools/components/DetailPanel";
import {
    IFeatureModel,
    IHistoricalValueSetModel,
    ISecurityTypeModel,
    IShareClassModel,
    IUniverseScopeTypeModel,
    SecurityModel,
    SecuritySummaryModel
} from "proxy/apiProxy";
import { Formik, FormikHelpers, FormikProps } from 'formik';
import HistoricalValues, { IDataSeries } from "components/global/HistoricalValues";
import SecurityData from "./SecurityData";
import { useGrants, useReduxActions, useReduxSelections } from "tools/lib/reduxStoreAccess";
import DetailPanelLoading from "tools/components/DetailPanelLoading";
import SecurityProxy from "./SecurityProxy";
import { isNumber, oProps, useNumber } from "tools/lib/utility";
import { getSecurityTypeLabel } from "./getSecurityTypeLabel";
import * as React from "react";
import { useEffect } from "react";
import { ISecurityData } from "features/Security/slice";
import { Chip } from "@material-ui/core";
import WriteAccess from "tools/fieldComponents/WriteAccess";
import Ratings from "components/global/Ratings";
import CustomViewDatas from "features/Relationship/CustomViewDatas";
import useScopeButtonsMenu from "components/global/useScopeButtonsMenu";
import MonitoringResultGroups from "components/global/MonitoringResults";
import BriefcaseAccountIcon from "mdi-material-ui/BriefcaseAccount";
import PlaylistAddIcon from "@material-ui/icons/PlaylistAdd";
import { AddHistoricalValue } from "features/Indexes/AddHistoricalValue";
import { ProcessExecutionList } from "features/ProcessExecution/ProcessExecutionList";
import NotebookCheckOutlineIcon from "mdi-material-ui/NotebookCheckOutline";
import { useScreenNavigate, useScreenParams } from "tools/routing/screenRouteHooks";
import { ExtendedDocumentsGrid, RelatedType } from "features/Document/DocumentsScreen/ExtendedDocumentsGrid";

export const securityScreen = {
    route: "/securitymanagement/securities/:id/:tab?" as const,
    tabs: {
        detail: "details",
        historicalValues: "historicalvalues",
        proxy: "proxy",
        ratings: "ratings",
        customViews: "customScreens",
        dashboard: "dashboard",
        processes: "processes",
        documents: "documents"
    },
    component: Security,
    label: "Security",
}

function Security() {
    const {
        referenceCountries = {},
        referenceCurrencies = {},
        ratingTypes = {}
    } = useReduxSelections("reference");
    const { macroScriptLoadAll } = useReduxActions("macroScript")
    const { macroScriptList } = useReduxSelections("macroScript");
    const {
        current,
        proxy = [],
        dictionaries,
        historicalValues,
        historicalValuesVisualizationType,
        loading,
        saving,
        historicalLoading,
        ratings,
        securitySaveHistoricalValueSaving,
        customScreenDataLoading,
        customScreenDatas,
        customScreens,
        customScreenDataSubmitting,
        monitoringResults,
        processExecutionsLoading,
        processExecutions,
        documentsLoading,
        documents
    } = useReduxSelections("security");
    const {
        securityDelete,
        securitySave,
        securityHistoricalValuesVisualizationTypeChanged,
        securityAddSecurityInDictionary,
        securityAddIndexInDictionary,
        securityAddSubFundInDictionary,
        securityLoadedRelationship,
        securityAddEntityInDictionary,
        securitySaveHistoricalValueSave,
        securityCustomScreenDataSubmit,
        securityMonitoringResultLoad,
        securityLoad, securityPricingDatesLoad, securityProcessExecutionsLoad, securityHistoricalValuesLoad,
        securityDocumentsLoad
    } = useReduxActions("security");
    const { classificationTypes } = useReduxSelections("securityClassificationType");
    const { parametersMarketDataProviders, parameters: { securityExtensionFields = [] } } = useReduxSelections("parameters");

    const { id, tab: tabValue = "detail" } = useScreenParams<typeof securityScreen>()
    const idNum = useNumber(id)

    useEffect(() => {
        macroScriptLoadAll()
        if (isNumber(idNum)) {
            securityLoad(idNum)
            securityPricingDatesLoad(idNum)
        } else if (id && id in ISecurityTypeModel) {
            securityLoad(id as ISecurityTypeModel)
        }
    }, [id, idNum, macroScriptLoadAll, securityLoad, securityPricingDatesLoad]);

    useEffect(() => {
        if (isNumber(idNum) && tabValue === "ratings" && !ratings) {
            securityPricingDatesLoad(idNum)
        }
    }, [idNum, ratings, securityPricingDatesLoad, tabValue]);

    useEffect(() => {
        if (isNumber(idNum) && tabValue === "processes" && !processExecutions) {
            securityProcessExecutionsLoad(idNum)
        }
    }, [idNum, processExecutions, securityProcessExecutionsLoad, tabValue]);

    useEffect(() => {
        if (isNumber(idNum) && tabValue === "documents" && !documentsLoading && !documents)
            securityDocumentsLoad(idNum)
    }, [idNum, securityDocumentsLoad, documents, documentsLoading, tabValue]);

    useEffect(() => {
        if (isNumber(idNum) && tabValue === "historicalValues" && !historicalValues) {
            securityHistoricalValuesLoad(idNum)
        }
    }, [historicalValues, idNum, securityHistoricalValuesLoad, tabValue]);

    const navigate = useScreenNavigate()

    const monitoringMacros = React.useMemo(() => macroScriptList.filter(i => (i.type === "MonitoringMacroScriptModel" || i.type === "MonitoringMacroScriptSummaryModel") && i.singleScope === IUniverseScopeTypeModel.Security), [macroScriptList]);

    const formValues: ISecurityData | undefined = React.useMemo(() => {
        if (!current) {
            return undefined;
        }
        const ret: ISecurityData = { security: current, proxy };
        return ret;
    }, [current, proxy]);
    const isGranted = useGrants();

    const [addingNewHistValue, setAddingNewHistValue] = React.useState(false);
    const extraReportActionButtons = useScopeButtonsMenu(IUniverseScopeTypeModel.Security, current?.id);
    const handleNewExecutionProcess = React.useCallback(() => {
        navigate("ProcessExecutionNew", { type: "SecurityProcessExecutionModel", targetId: current?.id ?? 0 });
    }, [current?.id, navigate])
    if (!formValues || !current) {
        return <DetailPanelLoading tabNumber={2} hasSubTitle={true} />;
    }
    const title = !!current.id ? (current.name || "") : `New ${getSecurityTypeLabel(current.type)}`;
    const historicalValueProperties = getHistoricalValueTypes(current.type);
    const graphHistoricalValueProperties = getGraphHistoricalValueTypes(current.type);

    const parametersMarketDataProvidersInUse = parametersMarketDataProviders;

    const handleDelete = () => {
        if (current?.id) {
            securityDelete(current.id);
        }
    }
    const handleSubmit = (values: ISecurityData, { setSubmitting }: FormikHelpers<ISecurityData>) => {
        securitySave(values);
        setSubmitting(false);
    }
    const handleSecuritySelected = (securityId: number) => {
        const { type } = dictionaries.securities[securityId];
        switch (type) {
            case "LoanSummaryModel":
                navigate("Loan", { id: securityId });
                break;
            case "CashSummaryModel":
                navigate("BankAccount", { id: securityId });
                break;
            default:
                navigate("Security", { id: securityId });
                break;
        }
    }
    const handleLoadResult = (id: number) => securityMonitoringResultLoad({ id, targetId: current.id });
    const handleIndexSelected = (indexId: number) => {
        navigate("Index", { id: indexId });
    }
    const handleValueChanged = (date: Date, type: string, value: number | undefined) => {
        securitySaveHistoricalValueSave({
            historicalValue: {
                date,
                type,
                value: value
            },
            securityId: current.id
        })
    }

    const handleDataSubmit = (customScreenId: number, values: any) => securityCustomScreenDataSubmit({
        id: formValues.security.id,
        customScreenId,
        values
    });
    const canEditCustomData = isGranted(IFeatureModel.UniverseCustomDataWrite);

    return <Formik onSubmit={handleSubmit} initialValues={formValues} enableReinitialize={true}
        validateOnMount={true}>{renderForm}</Formik>;

    function renderForm({ dirty, isValid, submitForm, values, validateForm, setFieldValue }: FormikProps<ISecurityData>) {
        const tabs: ISubMenuTabs<typeof securityScreen>[] = [{
            label: "Data",
            value: "detail"
        }, {
            label: "Proxy",
            value: "proxy"
        }];
        if (!!monitoringMacros.length) {
            tabs.push({
                label: "Dashboard",
                value: "dashboard"
            });
        }
        if (values.security.id) {
            tabs.push({
                label: "Historical series",
                value: "historicalValues"
            });
            tabs.push({
                label: "Processes",
                value: "processes"
            });
            tabs.push({
                label: "Documents",
                value: "documents"
            });
        }
        if (values.security.type === "BondModel" && !!ratings) {
            tabs.push({
                label: "Ratings",
                value: "ratings"
            });
        }
        if (values?.security?.id && !!customScreens && customScreens.length) {
            tabs.push({
                label: "Custom Data",
                value: "customViews"
            });
        }

        const extraActionButtons = (() => {
            const ret = [...extraReportActionButtons];
            if (tabValue === "historicalValues") {
                ret.push({
                    label: "Add new Historical Value",
                    onClick: () => setAddingNewHistValue(true),
                    icon: PlaylistAddIcon
                });
            }
            ret.push({
                label: "Add Process Execution",
                icon: NotebookCheckOutlineIcon,
                onClick: handleNewExecutionProcess,
                feature: IFeatureModel.UniverseDueDiligenceWrite//,
            });
            return ret;
        })();
        const handleCloseNewHistValue = (hv: IHistoricalValueSetModel) => {
            setAddingNewHistValue(false);
            if (current?.id) {
                securitySaveHistoricalValueSave({
                    historicalValue: hv,
                    securityId: current.id
                });
            }
        };
        const handleCloseNewHistValueCancel = () => setAddingNewHistValue(false);
        return <DetailPanel
            endAdornment={(values.security.type === "ShareClassModel" && values.security.isUnderManagement) ?
                <Chip icon={<BriefcaseAccountIcon />} size="small" label="Under management" /> : undefined}
            isQuerying={loading || historicalLoading || saving || securitySaveHistoricalValueSaving || customScreenDataLoading || customScreenDataSubmitting || processExecutionsLoading}
            title={title}
            subTitle={getSecurityTypeLabel(values.security.type)}
            canSave={dirty && isValid}
            onSaveClick={values.security.type === "ShareClassModel" && values.security.isUnderManagement ? undefined : submitForm}
            canDelete={!!values.security.id}
            onDeleteClick={values.security.type === "ShareClassModel" && values.security.isUnderManagement ? undefined : handleDelete}
            saveAllowed={IFeatureModel.UniverseWrite}
            deleteAllowed={IFeatureModel.UniverseDelete}
            badge={!values.security.id ? "new" : undefined}
            tabs={tabs}
            actions={extraActionButtons}
            tabValue={tabValue}>
            <AddHistoricalValue
                isOpened={addingNewHistValue}
                onAdd={handleCloseNewHistValue}
                onCancel={handleCloseNewHistValueCancel}
                typeDescriptions={historicalValueProperties} />

            {(tabValue === "detail") && <WriteAccess value={IFeatureModel.UniverseWrite}><SecurityData
                securityExtensionFields={securityExtensionFields}
                formFieldName={oProps<ISecurityData>().path("security")}
                onRelationshipLoaded={securityLoadedRelationship}
                formValues={values.security}
                referenceCountries={referenceCountries}
                referenceCurrencies={referenceCurrencies}
                previousSubFundId={(values.security as IShareClassModel).subFundId}
                onSubFundLoaded={securityAddSubFundInDictionary}
                dictionaries={dictionaries}
                classificationTypes={classificationTypes}
                onEntityLoaded={securityAddEntityInDictionary}
                parametersMarketDataProviders={parametersMarketDataProvidersInUse} /></WriteAccess>}
            {(tabValue === "dashboard" && !!monitoringMacros.length) && <MonitoringResultGroups
                monitoringMacros={monitoringMacros}
                onLoadResult={handleLoadResult}
                resultGroups={monitoringResults} />}
            {(tabValue === "proxy") && <WriteAccess value={IFeatureModel.SecurityWriteProxy}><SecurityProxy
                fieldPath={oProps<ISecurityData>().path("proxy")}
                referenceCountries={referenceCountries}
                referenceCurrencies={referenceCurrencies}
                dictionaries={dictionaries}
                onShareClassLoaded={securityAddSecurityInDictionary}
                onIndexLoaded={securityAddIndexInDictionary}
                onSecurityClick={handleSecuritySelected}
                onIndexClick={handleIndexSelected} /></WriteAccess>}
            {(tabValue === "historicalValues") && <HistoricalValues
                historicalValues={historicalValues}
                properties={historicalValueProperties}
                graphProperties={graphHistoricalValueProperties}
                onVisualizationTypeChanged={securityHistoricalValuesVisualizationTypeChanged}
                visualizationType={historicalValuesVisualizationType}
                editable={isGranted(IFeatureModel.UniverseWrite)}
                onValueChanged={handleValueChanged}
            />}
            {(tabValue === "ratings" && !!ratings) && <Ratings
                ratingTypes={ratingTypes}
                ratings={ratings} />
            }
            {(tabValue === "customViews" && !!customScreenDatas && !!customScreens?.length) && <CustomViewDatas
                canEdit={canEditCustomData}
                dictionaries={dictionaries}
                customScreenLoading={customScreenDataLoading}
                dataSubmitting={customScreenDataSubmitting}
                customScreens={customScreens}
                onDataSubmit={handleDataSubmit}
                customScreenDatas={customScreenDatas} />
            }
            {(tabValue === "processes") && <ProcessExecutionList
                processExecutions={processExecutions}
                dictionaries={dictionaries} />
            }
            {(tabValue === "documents") && <ExtendedDocumentsGrid
                documents={documents ?? []}
                dictionaries={dictionaries}
                relatedType={RelatedType.Security}
                relatedId={values.security.id} />}
        </DetailPanel >;
    }
}

function getGraphHistoricalValueTypes(securityType: (SecurityModel | SecuritySummaryModel)["type"]): IDataSeries {
    switch (securityType) {
        case "EquityModel":
        case "EquitySummaryModel":
            return {
                MKT: "Price",
                // "TRP": "Total return price",
                // "DIV": "Dividend",
                // "VOL": "Volatility",
                // "CAP": "Market capitalization",
                // "FFL": "Free float",
            };
        case "ShareClassModel":
        case "ShareClassSummaryModel":
            return {
                MKT: "NAV",
                // "TNA": "Total net asset",
                // "TRP": "Total return price",
                // "DIV": "Dividend",
                // "NBS": "Number of shares",
                // "SRRI": "SRRI",
                // "SUB": "Subscription",
                // "RED": "Redemption",
                // "TER": "Total expense ratio",
                // "OGC": "Ongoing charges",
            };
        case "BondModel":
        case "BondSummaryModel":
            return {
                MKT: "Clean price",
                BDP: "Dirty price",
                // "ACC": "Accrued interest",
            };
        case "CashSummaryModel":
            return {
                MKT: "Price",
            };
        case "FxForwardModel":
        case "FxForwardSummaryModel":
            return {
                MKT: "Price",
            };
        case "CommodityModel":
        case "CommoditySummaryModel":
            return {
                MKT: "Price",
            };
        // case "Option":
        //     return {
        //         "DTA": "Delta",
        //     };
        // case "Future":
        //     return {
        //         "DTA": "Delta",
        //     };
    }
    return {};
}

function getHistoricalValueTypes(securityType: (SecurityModel | SecuritySummaryModel)["type"]): IDataSeries {
    switch (securityType) {
        case "EquityModel":
        case "EquitySummaryModel":
            return {
                MKT: "Price",
                TRP: { title: "Adjusted price", columnType: "preciseDecimal" },
                VOLU: "Volume",
                DIV: "Dividend",
                //[IHistoricalValueTypeModel.VOLA]: "Volatility",
                CAP: "Market capitalization",
                FFL: "Free float",
                ESG: "ESG Rating",
                BID: "Bid",
                ASK: "Ask",
                DYLD: { title: "Div. Yield", columnType: "precisePercentage2" },
                PER: "P/E Ratio",
                FPER: "Forward P/E",
                BETA: "Beta",
                DTEQ: "Debt To Equity",
                DTMC: "Debt To MarketCap",
                DIVU: "Dividend unadjusted",
                SPLT: "Split ratio",
            };
        case "EtfModel":
        case "EtfSummaryModel":
            return {
                MKT: "Price",
                TRP: { title: "Adjusted price", columnType: "preciseDecimal" },
                DIV: "Dividend",
                //[IHistoricalValueTypeModel.VOLA]: "Volatility",
                CAP: "Market capitalization",
                FFL: "Free float",
                DIVU: "Dividend unadjusted",
            };
        case "ShareClassModel":
        case "ShareClassSummaryModel":
            return {
                MKT: "NAV",
                TNA: "Total net asset",
                TRP: { title: "Adjusted price", columnType: "preciseDecimal" },
                DIV: "Dividend",
                NBS: "Number of shares",
                SRRI: "SRRI",
                SUB: "Subscription",
                RED: "Redemption",
                TER: "Total expense ratio",
                OGC: "Ongoing charges",
                FSU: "Forecasted subscription",
                FRE: "Forecasted redemption",
                DIVU: "Dividend unadjusted",
            };
        case "BondModel":
        case "BondSummaryModel":
            return {
                MKT: "Clean price",
                BDP: "Dirty price",
                ACC: "Accrued interest",
                YTM: "Yield to maturity",
            };
        // case "Cash":
        //     return {};
        //     // return {
        //     //     [IHistoricalValueType.MKT]: "Price",
        //     // };
        case "FxForwardModel":
        case "FxForwardSummaryModel":
            return {
                MKT: "Price",
            };
        case "CommodityModel":
        case "CommoditySummaryModel":
            return {
                MKT: "Price",
            };
        // case ISecurityTypeModel.Option:
        //     return {};
        //     //  {
        //     //     [IHistoricalValueType.DTA]: "Delta",
        //     // };
        // case ISecurityTypeModel.Future:
        //     return {};
        //     // return {
        //     //     [IHistoricalValueType.DTA]: "Delta",
        //     // };
    }
    return {};
}
