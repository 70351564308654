import ClassificationsData from "components/global/ClassificationsData";
import {
    EntitySummaryModel, IClassificationTypeModel, ICurrencyModel, PortfolioSummaryModel,
    SecuritySummaryModel, IBankAccountSummaryModel, IEntityTypeModel, IFeatureModel,
    ICashMovementModel
} from "proxy/apiProxy";
import FieldBox from "tools/components/FieldBox";
import FormTextField from "tools/fieldComponents/FormTextField";
import { oProps } from "tools/lib/utility";
import { Box, Grid } from "@material-ui/core";
import FormDatePickerField from "tools/fieldComponents/FormDatePickerField";
import FormCheckBoxField from "tools/fieldComponents/FormCheckBoxField";
import EntitySelectField from "components/searchers/EntitySelectField";
import { IGetBankAccountSummary, IGetPortfolioSummary, IGetEntitySummary, IGetSecuritySummary } from "features/Reference/slice";
import ManagedPortfolioSelectField from "components/searchers/ManagedPortfolioSelectField";
import SecuritySelectField from "components/searchers/SecuritySelectField";
import EntitySummary from "components/summaries/EntitySummary";
import ManagedPortfolioSummary from "components/summaries/ManagedPortfolioSummary";
import SecuritySummary from "components/summaries/SecuritySummary";
import { BankAccountSelectField } from "components/searchers/BankAccountSelectField";
import BankAccountSummary from "components/summaries/BankAccountSummary";
import { CurrencySelectField } from "components/searchers/CurrencySelectField";
import { useGrants } from "tools/lib/reduxStoreAccess";
import FormMultiCulturedTextField from "tools/fieldComponents/FormMultiCulturedTextField";

export interface ICashMovementDataProps {
    classificationTypes: IClassificationTypeModel[];
    referenceCurrencies?: Record<number | string, ICurrencyModel>;
    onEntitySelected: (found: IGetEntitySummary) => void;
    onPortfolioSelected: (found: IGetPortfolioSummary) => void;
    onSecuritySelected: (found: IGetSecuritySummary) => void;
    onBankAccountSelected: (found: IGetBankAccountSummary) => void;
    dictionaries: {
        entities: Record<number, EntitySummaryModel>;
        portfolios: Record<number, PortfolioSummaryModel>;
        securities: Record<number, SecuritySummaryModel>;
        bankAccounts: Record<number, IBankAccountSummaryModel>;
    }
}

export default function CashMovementData({
    classificationTypes,
    referenceCurrencies = {},
    dictionaries,
    onEntitySelected,
    onPortfolioSelected,
    onSecuritySelected,
    onBankAccountSelected
}: ICashMovementDataProps) {
    const isGranted = useGrants();
    const isPortfolioGranted = ({ id: portfolioId }: PortfolioSummaryModel) => isGranted({ feature: IFeatureModel.ManagedPortfolioWrite, portfolioId });

    return <FieldBox display="flex" flexDirection="column">
        <ClassificationsData
            panelTitle="Classification"
            fieldName={oProps<ICashMovementModel>().path("classifications")}
            classificationTypes={classificationTypes} />
        <Grid container={true} spacing={1}>
            <Grid item={true} sm={3} md={3} lg={3}>
                <FormTextField name={oProps<ICashMovementModel>().path("description")} label="Description" required={true} />
            </Grid>
            <Grid item={true} sm={3} md={3} lg={3}>
                <FormTextField name={oProps<ICashMovementModel>().path("externalTransactionCode")} label="External Transaction Code" />
            </Grid>
            <Grid item={true} sm={3} md={3} lg={3}>
                <FormTextField name={oProps<ICashMovementModel>().path("transactionCode")} label="Transaction Code" required={true} />
            </Grid>
            <Grid item={true} sm={3} md={3} lg={3}>
                <FormTextField name={oProps<ICashMovementModel>().path("movementCode")} label="Movement Code" required={true} />
            </Grid>
            <Grid item={true} sm={3} md={3} lg={3}>
                <FormDatePickerField name={oProps<ICashMovementModel>().path("closingDate")} label="Closing Date" />
            </Grid>
            <Grid item={true} sm={3} md={3} lg={3}>
                <FormDatePickerField name={oProps<ICashMovementModel>().path("tradeDate")} label="Trade Date" />
            </Grid>
            <Grid item={true} sm={3} md={3} lg={3}>
                <FormDatePickerField name={oProps<ICashMovementModel>().path("valueDate")} label="Value Date" required={true} />
            </Grid>
            <Grid item={true} sm={3} md={3} lg={3}>
                <FormCheckBoxField name={oProps<ICashMovementModel>().path("reversal")} label="Reversal" />
            </Grid>
            <Grid item={true} sm={3} md={3} lg={3}>
                <CurrencySelectField name={oProps<ICashMovementModel>().path("currencyId")} label="Currency" required={false} />
            </Grid>

            <Grid item={true} sm={3} md={3} lg={3}>
                <FormTextField name={oProps<ICashMovementModel>().path("statementNumber")} label="Statement Number" isNumber />
            </Grid>
            <Grid item={true} sm={3} md={3} lg={3}>
                <FormDatePickerField name={oProps<ICashMovementModel>().path("openingBalanceDate")} label="Opening Balance Date" />
            </Grid>
            <Grid item={true} sm={3} md={3} lg={3}>
                <FormTextField name={oProps<ICashMovementModel>().path("openingBalance")} label="Opening Balance" isNumber />
            </Grid>
            <Grid item={true} sm={3} md={3} lg={3}>
                <FormDatePickerField name={oProps<ICashMovementModel>().path("closingBalanceDate")} label="Closing Balance Date" />
            </Grid>
            <Grid item={true} sm={3} md={3} lg={3}>
                <FormTextField name={oProps<ICashMovementModel>().path("closingBalance")} label="Closing Balance" isNumber />
            </Grid>

            <Grid item={true} sm={8}>
                <FormMultiCulturedTextField name={oProps<ICashMovementModel>().path("comment")} label="Comment" multiline={true} />
            </Grid>
        </Grid>
        <Grid container={true} spacing={1}>
            <Grid item={true} sm={3} md={3} lg={3}>
                <FormTextField name={oProps<ICashMovementModel>().path("brokerageFeesInSecurityCcy")} label="Brokerage Fees In Security Ccy" isNumber={true} />
            </Grid>
            <Grid item={true} sm={3} md={3} lg={3}>
                <FormTextField name={oProps<ICashMovementModel>().path("feesInSecurityCcy")} label="Fees In Security Ccy" isNumber={true} />
            </Grid>
            <Grid item={true} sm={3} md={3} lg={3}>
                <FormTextField name={oProps<ICashMovementModel>().path("grossAmountInSecurityCcy")} label="Gross Amount In Security Ccy" isNumber={true} adornment />
            </Grid>
            <Grid item={true} sm={3} md={3} lg={3}>
                <FormTextField name={oProps<ICashMovementModel>().path("grossAmountInPortfolioCcy")} label="Gross Amount In Portfolio Ccy" isNumber={true} />
            </Grid>
            <Grid item={true} sm={3} md={3} lg={3}>
                <FormTextField name={oProps<ICashMovementModel>().path("netAmountInSecurityCcy")} label="Net Amount In Security Ccy" isNumber={true} />
            </Grid>
            <Grid item={true} sm={3} md={3} lg={3}>
                <FormTextField name={oProps<ICashMovementModel>().path("netAmountInPortfolioCcy")} label="Net Amount In Portfolio Ccy" isNumber={true} />
            </Grid>
            <Grid item={true} sm={3} md={3} lg={3}>
                <FormTextField name={oProps<ICashMovementModel>().path("priceInSecurityCcy")} label="Price In Security Ccy" isNumber={true} />
            </Grid>
            <Grid item={true} sm={3} md={3} lg={3}>
                <FormTextField name={oProps<ICashMovementModel>().path("taxesInSecurityCcy")} label="Taxes In Security Ccy" isNumber={true} />
            </Grid>
            <Grid item={true} sm={3} md={3} lg={3}>
                <FormTextField name={oProps<ICashMovementModel>().path("quantity")} label="Quantity" isNumber={true} />
            </Grid>
        </Grid>
        <Grid container={true} spacing={1}>
            <Grid item={true} sm={3} md={3} lg={3}>
                <FormDatePickerField name={oProps<ICashMovementModel>().path("voucherDate")} label="Voucher Date" />
            </Grid>
            <Grid item={true} sm={3} md={3} lg={3}>
                <FormTextField name={oProps<ICashMovementModel>().path("voucherNumber")} label="Voucher Number" />
            </Grid>
            <Grid item={true} sm={3} md={3} lg={3}>
                <FormTextField name={oProps<ICashMovementModel>().path("voucherAmount")} label="Voucher Amount" isNumber={true} />
            </Grid>
        </Grid>
        <Grid container={true} spacing={1}>
            <Grid item={true} sm={6} md={6} lg={6}>
                <Box>
                    <EntitySelectField name={oProps<ICashMovementModel>().path("counterpartyId")} label="Counterparty" type={IEntityTypeModel.Company} onSelected={onEntitySelected} />
                    <EntitySummary name={oProps<ICashMovementModel>().path("counterpartyId")} {...dictionaries} />
                </Box>
            </Grid>
            <Grid item={true} sm={6} md={6} lg={6}>
                <Box>
                    <ManagedPortfolioSelectField name={oProps<ICashMovementModel>().path("portfolioId")} filter={isPortfolioGranted} label="Portfolio" onSelected={onPortfolioSelected} required={false} />
                    <ManagedPortfolioSummary name={oProps<ICashMovementModel>().path("portfolioId")} currencies={referenceCurrencies} {...dictionaries} />
                </Box>
            </Grid>
            <Grid item={true} sm={6} md={6} lg={6}>
                <Box>
                    <SecuritySelectField name={oProps<ICashMovementModel>().path("underlyingSecurityId")} label="Underlying Security" onSelected={onSecuritySelected} />
                    <SecuritySummary name={oProps<ICashMovementModel>().path("underlyingSecurityId")} currencies={referenceCurrencies} {...dictionaries} />
                </Box>
            </Grid>
            <Grid item={true} sm={6} md={6} lg={6}>
                <Box>
                    <BankAccountSelectField name={oProps<ICashMovementModel>().path("cashId")} label="Bank Account" onSelected={onBankAccountSelected} />
                    <BankAccountSummary name={oProps<ICashMovementModel>().path("cashId")} currencies={referenceCurrencies} {...dictionaries} />
                </Box>
            </Grid>
        </Grid>
    </FieldBox>;
}