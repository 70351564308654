import { Grid } from "@material-ui/core";
import { DocumentsGrid, IDocumentsGridProps } from "./DocumentsGrid";
import { createNewDocumentModelInstance } from "../utils";
import { fileToBase64 } from "tools/lib/utility";
import { useReduxActions, useReduxSelections } from "tools/lib/reduxStoreAccess";
import DropZone from "tools/components/DropZone";
import { useEffect } from "react";
import React from "react";

export enum RelatedType { Entity, Relationship, Portfolio, Security, Transaction, CashMovement }

interface IExtendedDocumentsGridProps extends IDocumentsGridProps {
    relatedType: RelatedType;
    relatedId: number;
}

export function ExtendedDocumentsGrid({ documents, dictionaries, relatedType, relatedId }: IExtendedDocumentsGridProps) {
    const [value, setValue] = React.useState(documents);

    const { documentCurrent } = useReduxSelections("document");
    const { documentSave } = useReduxActions("document");

    const handleFileOpen = async (file: File) => {
        const openedFile = await fileToBase64(file);
        const document = createNewDocumentModelInstance();
        document.alias = file.name;
        document.name = file.name;

        switch (relatedType) {
            case RelatedType.Entity: document.related.entityIds = [relatedId]; break;
            case RelatedType.Relationship: document.related.relationshipIds = [relatedId]; break;
            case RelatedType.Portfolio: document.related.portfolioIds = [relatedId]; break;
            case RelatedType.Security: document.related.securityIds = [relatedId]; break;
            case RelatedType.Transaction: document.related.transactionIds = [relatedId]; break;
            case RelatedType.CashMovement: document.related.cashMovementIds = [relatedId]; break;
        }

        documentSave({ document, file: openedFile });
    };

    useEffect(() => {
        if (documentCurrent) {
            setValue([...value, documentCurrent]);
        }
    }, [documentCurrent]);

    useEffect(() => {
        setValue(documents);
    }, [documents]);

    return <Grid container={true}>
        <Grid item={true} md={3}>
            <DropZone
                emptyLabel={"Drop the file to map here"}
                onFileOpen={handleFileOpen}
            />
        </Grid>
        <DocumentsGrid
            documents={value}
            dictionaries={dictionaries}
        />
    </Grid>
}
