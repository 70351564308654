import * as React from "react";
import { useEffect } from "react";
import DetailPanel, { ISubMenuTabs } from "tools/components/DetailPanel";
import {
    ICounterpartyTypeModel,
    IFeatureModel,
    IInvestorRelationshipModel,
    IRoleRelationshipModel,
    IUniverseScopeTypeModel,
    RelationshipModel
} from "proxy/apiProxy";
import { Formik, FormikHelpers, FormikProps } from 'formik';
import RelationshipData from "./RelationshipData";
import AccessManagement from "./AccessManagement";
import { useGrants, useReduxActions, useReduxSelections } from "tools/lib/reduxStoreAccess";
import { getEnumLabels, GrantRequest, isNumber, oProps, useNumber } from "tools/lib/utility";
import KeyRemoveIcon from "mdi-material-ui/KeyRemove";
import { IActionButton } from "tools/components/FabContainer";
import { IGetEntitySummary } from "features/Reference/slice";
import DetailPanelLoading from "tools/components/DetailPanelLoading";
import { getEntityName } from "tools/lib/modelUtils";
import WriteAccess from "tools/fieldComponents/WriteAccess";
import { getRelationshipTypeLabel } from "./getRelationshipTypeLabel";
import CustomViewDatas from "./CustomViewDatas";
import useScopeButtonsMenu from "components/global/useScopeButtonsMenu";
import MonitoringResultGroups from "components/global/MonitoringResults";
import InvestorOperations from "./InvestorOperations";
import { roleCollaborationTypes, roleDomains } from "./FormInterface";
import { ProcessExecutionList } from "features/ProcessExecution/ProcessExecutionList";
import NotebookCheckOutlineIcon from "mdi-material-ui/NotebookCheckOutline";
import { useScreenNavigate, useScreenParams } from "tools/routing/screenRouteHooks";
import { ExtendedDocumentsGrid, RelatedType } from "features/Document/DocumentsScreen/ExtendedDocumentsGrid";

export const counterpartyRelationshipScreen = {
    component: CounterpartyRelationship,
    route: "/counterparties/:id/:tab?" as const,
    label: "Investor Relationship",
    tabs: {
        detail: "details",
        customViews: "customScreens",
        dashboard: "dashboard",
        processes: "processes",
        documents: "documents"
    },
    parentListScreenKey: "Counterparties" as const,
    type: "CounterpartyRelationshipModel" as RelationshipModel["type"],
}

function CounterpartyRelationship() {
    return <Relationship screen={counterpartyRelationshipScreen} />;
}

export const investorRelationshipScreen = {
    component: InvestorRelationship,
    route: "/investors/:id/:tab?" as const,
    label: "Investor Relationship",
    tabs: {
        detail: "details",
        profile: "profile",
        customViews: "customScreens",
        dashboard: "dashboard",
        commitments: "commitments",
        processes: "processes",
        documents: "documents"
    },
    parentListScreenKey: "Investors" as const,
    type: "InvestorRelationshipModel" as RelationshipModel["type"],
}

function InvestorRelationship() {
    return <Relationship screen={investorRelationshipScreen} />;
}


export const roleRelationshipScreen = {
    component: RoleRelationship,
    route: "/collaborators/:id/:tab?" as const,
    label: "Role Relationship",
    tabs: {
        detail: "details",
        permissions: "permissions",
        customViews: "customScreens",
        dashboard: "dashboard",
        processes: "processes",
        documents: "documents"
    },
    parentListScreenKey: "ServiceProvidersCollaborators" as const,
    type: "RoleRelationshipModel" as RelationshipModel["type"],
}

function RoleRelationship() {
    return <Relationship screen={roleRelationshipScreen} />;
}

const counterpartyTypes = getEnumLabels(ICounterpartyTypeModel);


type RelationshipScreensTypes =
    typeof roleRelationshipScreen
    | typeof investorRelationshipScreen
    | typeof counterpartyRelationshipScreen

function Relationship({ screen }: { screen: RelationshipScreensTypes }) {
    const {
        relationshipSave,
        relationshipDeleteCurrent,
        relationshipLoadedEntity,
        relationshipLoadedPortfolio,
        relationshipLoadedRelationship,
        relationshipCustomScreenDataSubmit,
        relationshipMonitoringResultLoad,
        relationshipAddSecurityInDictionary,
        relationshipLoad,
        relationshipProcessExecutionsLoad,
        relationshipDocumentsLoad
    } = useReduxActions("relationship");
    const navigate = useScreenNavigate();
    const { classificationTypes: investorClassificationTypes } = useReduxSelections("investorClassificationType");
    const { classificationTypes: roleRelationshipClassificationTypes } = useReduxSelections("roleRelationshipClassificationType");
    const {
        current,
        loading,
        saving,
        dictionaries,
        customScreenDataLoading,
        customScreenDatas,
        customScreens,
        customScreenDataSubmitting,
        monitoringResults,
        processExecutionsLoading,
        processExecutions,
        documentsLoading,
        documents
    } = useReduxSelections("relationship");
    const { referenceCountries = {}, referenceCurrencies = {} } = useReduxSelections("reference");
    const { macroScriptList } = useReduxSelections("macroScript");
    const { macroScriptLoadAll } = useReduxActions("macroScript");

    const { id, tab: tabValue = "detail" } = useScreenParams<typeof screen>()
    const idNum = useNumber(id)

    useEffect(() => {
        if (isNumber(idNum)) {
            relationshipLoad({ id: idNum, type: screen.type })
            relationshipProcessExecutionsLoad({ id: idNum, type: screen.type })
        }
        macroScriptLoadAll()
    }, [idNum, macroScriptLoadAll, relationshipLoad, relationshipProcessExecutionsLoad, screen.type]);

    useEffect(() => {
        if (idNum && tabValue === "documents" && !documentsLoading && !documents)
            relationshipDocumentsLoad({ id: idNum, type: screen.type })
    }, [idNum, relationshipDocumentsLoad, documents, documentsLoading, tabValue]);

    const monitoringMacros = React.useMemo(() => {
        if (!current) {
            return [];
        }
        const sco = (function () {
            switch (current?.type) {
                case "CounterpartyRelationshipModel": return IUniverseScopeTypeModel.Counterparty;
                case "RoleRelationshipModel": return IUniverseScopeTypeModel.Role;
                case "InvestorRelationshipModel": return IUniverseScopeTypeModel.Investor;
                default: return IUniverseScopeTypeModel.Role
            }
        })();
        return macroScriptList.filter(i =>
            (i.type === "MonitoringMacroScriptModel" || i.type === "MonitoringMacroScriptSummaryModel")
            && i.singleScope === sco);
    }, [current, macroScriptList]);
    const isGranted = useGrants();
    const handleNewExecutionProcess = React.useCallback(() => {
        navigate("ProcessExecutionNew", {
            type: "RelationshipProcessExecutionModel",
            targetId: current?.id ?? 0
        });
    }, [current?.id, navigate])

    const extraReportActionButtons = useScopeButtonsMenu((function () {
        switch (current?.type) {
            case "CounterpartyRelationshipModel": return IUniverseScopeTypeModel.Counterparty;
            case "RoleRelationshipModel": return IUniverseScopeTypeModel.Role;
            case "InvestorRelationshipModel": return IUniverseScopeTypeModel.Investor;
            default: return IUniverseScopeTypeModel.Role
        }
    })(), current?.id);

    if (!current) {
        return <DetailPanelLoading tabNumber={3} hasSubTitle={false} />;
    }
    const handleLoadResult = (id: number) => relationshipMonitoringResultLoad({ id, targetId: current.id });
    const { entities } = dictionaries;
    const handleDelete = () => {
        const { id, type } = current;
        if (id) {
            relationshipDeleteCurrent({ id, type });
        }
    }

    const handleSubmit = (values: RelationshipModel, { setSubmitting }: FormikHelpers<RelationshipModel>) => {
        relationshipSave(values);
        setSubmitting(false);
    }

    const handleEntitySummaryChanged = (entitySummary: IGetEntitySummary | undefined) => {
        if (entitySummary) {
            relationshipLoadedEntity(entitySummary);
        }
    }
    const handleDataSubmit = (customScreenId: number, values: any) => relationshipCustomScreenDataSubmit({ id: current.id, type: current.type, customScreenId, values });
    const canEditCustomData = (function () {
        switch (current.type) {
            case "CounterpartyRelationshipModel": return isGranted(IFeatureModel.CounterpartyRelationshipCustomDataWrite);
            case "InvestorRelationshipModel": return isGranted({ feature: IFeatureModel.InvestorRelationshipCustomDataWrite, investorId: current.id });
            case "RoleRelationshipModel": return isGranted(IFeatureModel.RoleRelationshipCustomDataWrite);
        }
    })();

    return <Formik onSubmit={handleSubmit} initialValues={current} enableReinitialize={true} validateOnMount={true}>{renderForm}</Formik>;

    function renderForm({ dirty, isValid, submitForm, values, setFieldValue, validateForm }: FormikProps<RelationshipModel>) {

        if (!current) {
            return;
        }

        const { id, entityId } = values;
        const title = !!id ? ((getEntityName(entities[entityId])) ?? "") : `New ${getRelationshipTypeLabel(values.type)} relationship`;
        const tabs: ISubMenuTabs<typeof screen>[] = [{
            label: "Data",
            value: "detail"
        }];
        if (!!monitoringMacros.length) {
            tabs.push({
                label: "Dashboard",
                value: "dashboard"
            });
        }
        if (values?.id) {
            tabs.push({
                label: "Processes",
                value: "processes"
            });
            tabs.push({
                label: "Documents",
                value: "documents"
            });
        }
        if (values.type === "RoleRelationshipModel") {
            tabs.push({
                label: "Permissions",
                value: "permissions"
            });
        }
        if (values.type === "InvestorRelationshipModel") {
            tabs.push({
                label: "Commitments",
                value: "commitments"
            });
        }
        if (values?.id && !!customScreens && customScreens.length) {
            tabs.push({
                label: "Custom Data",
                value: "customViews"
            });
        }

        const handleSave = () => submitForm();

        const [deleteFeature, writeFeature] = (function (): [GrantRequest, GrantRequest, GrantRequest, GrantRequest] {
            switch (values?.type) {
                case "CounterpartyRelationshipModel": return [IFeatureModel.CounterpartyRelationshipDelete, IFeatureModel.CounterpartyRelationshipWrite, IFeatureModel.CounterpartyRelationshipDueDiligenceWrite, IFeatureModel.CounterpartyRelationshipDueDiligenceApprove];
                case "InvestorRelationshipModel": return [
                    { feature: IFeatureModel.InvestorRelationshipDelete, investorId: values?.id ?? 0 },
                    { feature: IFeatureModel.InvestorRelationshipWrite, investorId: values?.id ?? 0 },
                    { feature: IFeatureModel.InvestorRelationshipDueDiligenceWrite, investorId: values?.id ?? 0 },
                    { feature: IFeatureModel.InvestorRelationshipDueDiligenceApprove, investorId: values?.id ?? 0 },
                ];
                case "RoleRelationshipModel": return [IFeatureModel.RoleRelationshipDelete, IFeatureModel.RoleRelationshipWrite, IFeatureModel.RoleRelationshipDueDiligenceWrite, IFeatureModel.RoleRelationshipDueDiligenceApprove];
            }
        })();

        const extraActionButtons: IActionButton[] = [...extraReportActionButtons, {
            label: "Add Process Execution",
            icon: NotebookCheckOutlineIcon,
            onClick: handleNewExecutionProcess,
            feature: [IFeatureModel.RoleRelationshipDueDiligenceWrite, IFeatureModel.CounterpartyRelationshipDueDiligenceWrite, IFeatureModel.InvestorRelationshipDueDiligenceWrite]
        }];

        const handleClearRolePermissions = () => {
            setFieldValue(oProps<IRoleRelationshipModel>().path("permissions"), []);
        }

        if (current.type === "RoleRelationshipModel" && tabValue === "permissions") {
            extraActionButtons.unshift({
                label: "Remove All Permissions",
                onClick: handleClearRolePermissions,
                icon: KeyRemoveIcon,
                feature: IFeatureModel.RoleRelationshipWrite
            });
        }

        const subTitle = computeSubTitle();
        return <WriteAccess value={writeFeature}><DetailPanel
            isQuerying={loading || saving || customScreenDataLoading || customScreenDataSubmitting || processExecutionsLoading || documentsLoading}
            title={title}
            subTitle={subTitle}
            canSave={dirty && isValid}
            onSaveClick={handleSave}
            canDelete={!!id}
            onDeleteClick={handleDelete}
            actions={extraActionButtons}
            badge={!id ? "new" : undefined}
            saveAllowed={writeFeature}
            deleteAllowed={deleteFeature}
            tabs={tabs}
            tabValue={tabValue}>
            {(tabValue === "detail") && <RelationshipData
                investorClassificationTypes={investorClassificationTypes}
                roleRelationshipClassificationTypes={roleRelationshipClassificationTypes}
                onRelationshipLoaded={relationshipLoadedRelationship}
                referenceCountries={referenceCountries}
                referenceCurrencies={referenceCurrencies}
                onEntityLoaded={handleEntitySummaryChanged}
                onPortfolioLoaded={relationshipLoadedPortfolio}
                dictionaries={dictionaries} />}
            {(tabValue === "processes") && <ProcessExecutionList
                processExecutions={processExecutions ?? []}
                dictionaries={dictionaries} />}
            {(tabValue === "dashboard" && !!monitoringMacros.length) && <MonitoringResultGroups
                monitoringMacros={monitoringMacros}
                onLoadResult={handleLoadResult}
                resultGroups={monitoringResults} />}
            {(tabValue === "documents") && <ExtendedDocumentsGrid
                documents={documents ?? []}
                dictionaries={dictionaries}
                relatedType={RelatedType.Relationship}
                relatedId={values.id} />}
            {(tabValue === "commitments") && <InvestorOperations
                dictionaries={dictionaries}
                onSecurityLoaded={relationshipAddSecurityInDictionary}
                onPortfolioLoaded={relationshipLoadedPortfolio}
                referenceCurrencies={referenceCurrencies}
                formFieldName={oProps<IInvestorRelationshipModel>().path("investorOperations")} />}
            {(tabValue === "customViews" && !!customScreenDatas && !!customScreens?.length) && <CustomViewDatas
                canEdit={canEditCustomData}
                dictionaries={dictionaries}
                customScreenLoading={customScreenDataLoading}
                dataSubmitting={customScreenDataSubmitting}
                customScreens={customScreens}
                onDataSubmit={handleDataSubmit}
                customScreenDatas={customScreenDatas} />}
            {(tabValue === "permissions") && <AccessManagement formFieldName={oProps<IRoleRelationshipModel>().path("permissions")} />}
        </DetailPanel></WriteAccess>;
        function computeSubTitle() {
            switch (values.type) {
                case "RoleRelationshipModel":
                    return `${roleDomains[values.domain]}-${roleCollaborationTypes[values.collaborationType]}`;
                case "CounterpartyRelationshipModel":
                    if (values.counterpartyType) {
                        return counterpartyTypes[values.counterpartyType];
                    }
                    return "Counterparty";
                default:
                    return "Investor";
            }
        }
    }
}
