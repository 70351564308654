import DetailPanel, { ISubMenuTabs } from "tools/components/DetailPanel";
import DetailPanelLoading from "tools/components/DetailPanelLoading";
import { ICashMovementModel, IFeatureModel } from "proxy/apiProxy";
import { Formik, FormikHelpers, FormikProps } from 'formik';
import { useReduxActions, useReduxSelections } from "tools/lib/reduxStoreAccess";
import CashMovementData from "./CashMovementData";
import WriteAccess from "tools/fieldComponents/WriteAccess";
import { GrantRequest, isNumber, useNumber } from "tools/lib/utility";
import { useEffect, useMemo } from "react";
import { useScreenParams } from "../../tools/routing/screenRouteHooks";
import { ExtendedDocumentsGrid, RelatedType } from "features/Document/DocumentsScreen/ExtendedDocumentsGrid";

export const cashMovementScreen = {
    component: CashMovement,
    label: "Cash Movement",
    route: "/portfoliodata/cashMovements/:id/:tab?" as const,
    tabs: {
        detail: "detail",
        documents: "documents"
    },
}

function CashMovement() {
    const {
        cashMovementCurrent,
        cashMovementLoading,
        dictionaries,
        cashMovementSaving,
        documentsLoading,
        documents
    } = useReduxSelections("cashMovement");
    const { classificationTypes } = useReduxSelections("movementClassificationType");
    const { referenceCurrencies = {} } = useReduxSelections("reference");

    const {
        cashMovementLoad,
        cashMovementDelete,
        cashMovementSave,
        cashMovementEntitySelected,
        cashMovementPortfolioSelected,
        cashMovementSecuritySelected,
        cashMovementBankAccountSelected,
        cashMovementDocumentsLoad
    } = useReduxActions("cashMovement");

    const { id, tab: tabValue = "detail" } = useScreenParams<typeof cashMovementScreen>();
    const idNum = useNumber(id);

    useEffect(() => {
        if (isNumber(idNum)) cashMovementLoad(idNum);
    }, [cashMovementLoad, idNum]);

    useEffect(() => {
        if (idNum && tabValue === "documents" && !documentsLoading && !documents)
            cashMovementDocumentsLoad(idNum)
    }, [idNum, cashMovementDocumentsLoad, documents, documentsLoading, tabValue]);

    const formValues: ICashMovementModel | undefined = useMemo(() => {
        if (!cashMovementCurrent) {
            return undefined;
        }
        return cashMovementCurrent;
    }, [cashMovementCurrent]);

    if (!formValues) {
        return <DetailPanelLoading hasSubTitle={false} />;
    }
    const handleDelete = () => {
        if (!!cashMovementCurrent?.id) {
            cashMovementDelete(cashMovementCurrent.id);
        }
    }

    const title = !!cashMovementCurrent?.id ? cashMovementCurrent.description : 'Cash Movement';
    const handleSubmit = (values: ICashMovementModel, { setSubmitting }: FormikHelpers<ICashMovementModel>) => {
        cashMovementSave(values);
        setSubmitting(false);
    }
    return <Formik onSubmit={handleSubmit} initialValues={formValues} enableReinitialize={true} validateOnMount={true} >{renderForm}</Formik>;
    function renderForm({ dirty, isValid, submitForm, values }: FormikProps<ICashMovementModel>) {
        const grantRequest: GrantRequest[] = (!!cashMovementCurrent?.id && !!cashMovementCurrent.portfolioId)
            ? [{ feature: IFeatureModel.ManagedPortfolioWrite, portfolioId: cashMovementCurrent.portfolioId }] // if update: edition & save granted only if rights on the initial related portfolio (the target portfolio should be granted as only granted ones are supposed to be selectable)
            : [IFeatureModel.ManagedPortfolioWrite]; //if create: edition and save only if rights on an portfolio (as only granted target target portfolios are selectable)

        const tabs: ISubMenuTabs<typeof cashMovementScreen>[] = [{
            label: "Data",
            value: "detail"
        }];

        if (values?.id) {
            tabs.push({
                label: "Documents",
                value: "documents"
            });
        }

        return <WriteAccess value={grantRequest}>
            <DetailPanel
                isQuerying={cashMovementLoading || cashMovementSaving || documentsLoading}
                title={title}
                canSave={dirty && isValid}
                onSaveClick={submitForm}
                canDelete={!!cashMovementCurrent?.id}
                onDeleteClick={handleDelete}
                saveAllowed={grantRequest}
                deleteAllowed={grantRequest}
                badge={!cashMovementCurrent?.id ? "new" : undefined}
                tabs={tabs}
                tabValue={tabValue}>
                {(tabValue === "detail") && <CashMovementData
                    classificationTypes={classificationTypes}
                    referenceCurrencies={referenceCurrencies}
                    onEntitySelected={cashMovementEntitySelected}
                    onPortfolioSelected={cashMovementPortfolioSelected}
                    onSecuritySelected={cashMovementSecuritySelected}
                    onBankAccountSelected={cashMovementBankAccountSelected}
                    dictionaries={dictionaries} />}
                {(tabValue === "documents") && <ExtendedDocumentsGrid
                    documents={documents ?? []}
                    dictionaries={dictionaries}
                    relatedType={RelatedType.CashMovement}
                    relatedId={values.id} />}
            </DetailPanel>
        </WriteAccess>
    }
}
