import { PropertyGridEditorCollection, SurveyCreator, SurveyCreatorComponent } from "survey-creator-react";
import { useCallback, useRef } from "react";

import "survey-core/defaultV2.min.css";
import "survey-creator-core/survey-creator-core.min.css";
import { INotControllableEditorProps, useNotControllableEditor } from "./INotControllableEditorProps";
import { Base, Serializer, setLicenseKey } from "survey-core";

setLicenseKey("YjRhNWFkNWItNDMyMi00MGM0LTg3OGItYTZkZjc3MmRjMmYwOzE9MjAyNS0wNC0wMiwyPTIwMjUtMDQtMDI=");

export interface ISurveyDesignerProps extends INotControllableEditorProps<string> { }

const itemValueCheckBoxType = "itemValueCheckBoxType";

PropertyGridEditorCollection.register({
    fit: function (prop) {
        return prop.type === itemValueCheckBoxType;
    },
    getJSON: function (obj, prop, options) {
        return {
            type: "boolean",
            renderAs: "checkbox"
        };
    }
});

Serializer.addProperty("question", {
    name: "scoreWeight",
    displayName: "Score Weight",
    type: "number",
    category: "general",
    visibleIndex: 100,
    onSetValue: (survey: Base, value: number) => {
        survey.setPropertyValue("scoreWeight", value)
    }
});

Serializer.addProperty("itemvalue", {
    name: "score:number"
});

Serializer.addProperty("itemvalue", {
    name: "highRisk",
    type: itemValueCheckBoxType,
    default: false
});

Serializer.addProperties("boolean", [
    {
        name: "scoreTrue",
        displayName: "Scores for \"True\"",
        type: "number",
        category: "general",
        visibleIndex: 101,
        onSetValue: (survey: Base, value: number) => {
            survey.setPropertyValue("scoreTrue", value)
        }
    },
    {
        name: "scoreFalse",
        displayName: "Score for \"False\"",
        type: "number",
        category: "general",
        visibleIndex: 102,
        onSetValue: (survey: Base, value: number) => {
            survey.setPropertyValue("scoreFalse", value)
        }
    },
    {
        name: "highRisk",
        displayName: "High Risk Value",
        type: "itemvalue",
        category: "general",
        visibleIndex: 103,
        choices: [
            { value: true, text: "True" },
            { value: false, text: "False" },
            { value: null, text: "None" }
        ],
        onSetValue: (survey: Base, value: boolean) => {
            survey.setPropertyValue("highRisk", value)
        }
    }
]);

Serializer.addProperty("page", {
    name: "pageWeight",
    displayName: "Weight (in %)",
    type: "number",
    category: "general",
    minValue: 0,
    maxValue: 100,
    onSetValue: (survey: Base, value: number) => {
        survey.setPropertyValue("pageWeight", value)
    }
});

export default function SurveyDesigner(props: ISurveyDesignerProps) {
    const creatorRef = useRef(new SurveyCreator({
        showLogicTab: true,
        isAutoSave: true,
        showJSONEditorTab: true,
        showTranslationTab: true,
        showThemeTab: true
    }));

    const setter = useCallback((v?: string) => {
        creatorRef.current.JSON = JSON.parse(v ?? "{}");
    }, []);
    const getter = useCallback(() => JSON.stringify(creatorRef.current?.JSON), []);
    useNotControllableEditor(props, setter, getter);

    return <SurveyCreatorComponent creator={creatorRef.current} />
}
