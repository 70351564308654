import DetailPanel, { ISubMenuTabs } from "tools/components/DetailPanel";
import {
    IEntityPositionModel,
    IEntityTypeModel,
    IFeatureModel,
    isNonPersonEntityModel,
    IUniverseScopeTypeModel
} from "proxy/apiProxy";
import { Formik, FormikHelpers, FormikProps } from 'formik';
import { useGrants, useReduxActions, useReduxSelections } from "tools/lib/reduxStoreAccess";
import EntityData from "./EntityData";
import DetailPanelLoading from "tools/components/DetailPanelLoading";
import { getEntityName } from "tools/lib/modelUtils";
import * as React from "react";
import { useEffect } from "react";
import { IEntityData } from "features/Entity/slice";
import { isNumber, oProps, useNumber } from "tools/lib/utility";
import WriteAccess from "tools/fieldComponents/WriteAccess";
import Ratings from "components/global/Ratings";
import CustomViewDatas from "features/Relationship/CustomViewDatas";
import EntityList from "./EntityList";
import MonitoringResultGroups from "components/global/MonitoringResults";
import EntityComposition from "./EntityComposition";
import { ProcessExecutionList } from "../ProcessExecution/ProcessExecutionList";
import useScopeButtonsMenu from "components/global/useScopeButtonsMenu";
import { IActionButton } from "tools/components/FabContainer";
import NotebookCheckOutlineIcon from "mdi-material-ui/NotebookCheckOutline";
import { useScreenNavigate, useScreenParams } from "tools/routing/screenRouteHooks";
import EntityWhiteLabelData from "./EntityWhiteLabelData";
import { ExtendedDocumentsGrid, RelatedType } from "features/Document/DocumentsScreen/ExtendedDocumentsGrid";

export const personScreen = {
    type: IEntityTypeModel.Person,
    parentListScreenKey: "People" as const,
    component: Person,
    label: "Person",
    route: "/people/:id/:tab?" as const,
    tabs: {
        detail: "details",
        customViews: "customScreens",
        dashboard: "dashboard",
        documents: "documents",
        composition: "composition",
        theming: "theming",
        processes: "processes"
    },
}
export function Person() {
    return <Entity screen={personScreen} />
}

export const companyScreen = {
    type: IEntityTypeModel.Company,
    parentListScreenKey: "Companies" as const,
    component: Company,
    label: "Company",
    route: "/companies/:id/:tab?" as const,
    tabs: {
        detail: "details",
        entities: "entities",
        ratings: "ratings",
        customViews: "customScreens",
        dashboard: "dashboard",
        composition: "composition",
        documents: "documents",
        theming: "theming",
        processes: "processes"
    },
}
export function Company() {
    return <Entity screen={companyScreen} />
}

export const entityGroupScreen = {
    type: IEntityTypeModel.EntityGroup,
    parentListScreenKey: "EntityGroups" as const,
    component: EntityGroup,
    label: "EntityGroup",
    route: "/entitygroups/:id/:tab?" as const,
    tabs: {
        detail: "details",
        entities: "entities",
        customViews: "customScreens",
        dashboard: "dashboard",
        documents: "documents",
        theming: "theming",
        processes: "processes"
    },
}
export function EntityGroup() {
    return <Entity screen={entityGroupScreen} />
}
type EntityScreen = typeof entityGroupScreen | typeof companyScreen | typeof personScreen;
function Entity({ screen }: { screen: EntityScreen }) {
    const {
        entityCurrent,
        entityPicture,
        entityLoading,
        dictionaries,
        entitySaving,
        ratings,
        compositionDates,
        composition,
        customScreenDataLoading,
        customScreenDatas,
        customScreens,
        customScreenDataSubmitting,
        monitoringResults,
        currentCompositionDate,
        compositionDateLoading,
        compositionVisualizationType,
        compositionDatesLoading,
        processExecutionsLoading,
        processExecutions,
        documentsLoading,
        documents,
    } = useReduxSelections("entity");
    const { macroScriptList } = useReduxSelections("macroScript");
    const monitoringMacros = React.useMemo(() => macroScriptList.filter(i => (i.type === "MonitoringMacroScriptModel" || i.type === "MonitoringMacroScriptSummaryModel") && i.singleScope === IUniverseScopeTypeModel.Entity), [macroScriptList]);
    const { parameters: { personExtensionFields = [], companyExtensionFields = [], sicavExtensionFields = [] } } = useReduxSelections("parameters");
    const {
        referenceCountries = {},
        referenceCurrencies = {},
        ratingTypes = {}
    } = useReduxSelections("reference");
    const {
        classificationTypes: securityClassificationTypes,
        classificationTypesFlat: securityClassificationTypesFlat
    } = useReduxSelections("securityClassificationType");
    const {
        entityLoad,
        entitySave,
        entityDelete,
        entityAddEntityInDictionary,
        entityCompositionVisualizationTypeChange,
        entityCustomScreenDataSubmit,
        entityMonitoringResultLoad,
        entityDateLoad,
        entityDatesLoad,
        entityPositionDelete,
        entityPositionSave,
        entityAddSecurityInDictionary,
        entityProcessExecutionsLoad,
        entityRatingsLoad,
        entityDocumentsLoad
    } = useReduxActions("entity");
    const { classificationTypes } = useReduxSelections("entityClassificationType");

    const { id, tab: tabValue = "detail" } = useScreenParams<typeof screen>()
    const entityId = useNumber(id);
    // const tabValue = useScreenAnyTab(screen)

    useEffect(() => {
        if (isNumber(entityId))
            entityLoad(entityId)
        else if (screen.type)
            entityLoad(screen.type)
    }, [entityLoad, entityId, screen.type])

    useEffect(() => {
        if (entityId && tabValue === "composition")
            entityDatesLoad(entityId)
    }, [entityId, entityDatesLoad, tabValue])

    useEffect(() => {
        if (entityId && tabValue === "processes" && !processExecutionsLoading && !processExecutions)
            entityProcessExecutionsLoad(entityId)
    }, [entityId, entityProcessExecutionsLoad, processExecutions, processExecutionsLoading, tabValue])

    useEffect(() => {
        if (entityId && tabValue === "ratings" && ratings === undefined)
            entityRatingsLoad(entityId)
    }, [entityId, entityRatingsLoad, ratings, tabValue])

    useEffect(() => {
        if (entityId && tabValue === "documents" && !documentsLoading && !documents)
            entityDocumentsLoad(entityId)
    }, [entityId, entityDocumentsLoad, documents, documentsLoading, tabValue]);

    const handleDeletePosition = ({ id }: IEntityPositionModel) => entityPositionDelete(id);
    const formValues: IEntityData | undefined = React.useMemo(() => {
        if (!entityCurrent) {
            return undefined;
        }
        const ret: IEntityData = {
            entity: entityCurrent,
            imageFile: entityPicture
        };
        return ret;
    }, [entityCurrent, entityPicture]);
    const isGranted = useGrants();
    const extraReportActionButtons = useScopeButtonsMenu(IUniverseScopeTypeModel.Entity, entityCurrent?.id);
    const navigate = useScreenNavigate()
    const title = !!entityCurrent?.id ? (getEntityName(entityCurrent) ?? "") : 'New entity';
    const handleDelete = React.useCallback(() => {
        if (entityCurrent?.id) {
            entityDelete(entityCurrent.id);
        }
    }, [entityCurrent?.id, entityDelete])

    const handleSubmit = React.useCallback((values: IEntityData, { setSubmitting }: FormikHelpers<IEntityData>) => {
        entitySave(values);
        setSubmitting(false);
    }, [entitySave, formValues])

    const handleLoadResult = React.useCallback((id: number) => entityMonitoringResultLoad({ id, targetId: entityCurrent?.id ?? 0 }), [entityCurrent?.id, entityMonitoringResultLoad]);

    const handleEntitySelected = React.useCallback((entityId: number) => {
        const entity = dictionaries.entities[entityId];
        if (!entity) {
            return;
        }
        switch (entity.type) {
            case "PersonSummaryModel":
                navigate("Person", { id: entityId });
                break;
            case "EntityGroupSummaryModel":
                navigate("EntityGroup", { id: entityId });
                break;
            case "CompanySummaryModel":
                navigate("Company", { id: entityId });
                break;
            case "SicavSummaryModel":
                navigate("Sicav", { id: entityId });
                break;
        }
    }, [dictionaries.entities, navigate]);
    const handleNewExecutionProcess = React.useCallback(() => {
        navigate("ProcessExecutionNew", {
            type: "EntityProcessExecutionModel",
            targetId: entityCurrent?.id ?? 0
        });
    }, [entityCurrent?.id, navigate])

    const handleDataSubmit = React.useCallback((customScreenId: number, values: any) => entityCustomScreenDataSubmit({
        id: formValues?.entity?.id ?? 0,
        customScreenId,
        values
    }), [entityCustomScreenDataSubmit, formValues?.entity?.id]);
    const canEditCustomData = isGranted(IFeatureModel.UniverseCustomDataWrite);
    if (!entityCurrent || !formValues) {
        return <DetailPanelLoading tabNumber={2} hasSubTitle={false} />;
    }

    return <Formik onSubmit={handleSubmit} initialValues={formValues} enableReinitialize={true}
        validateOnMount={true}>{renderForm}</Formik>;

    function renderForm({ dirty, isValid, submitForm, values }: FormikProps<IEntityData>) {

        const tabs: ISubMenuTabs<typeof screen>[] = [{
            label: "Data",
            value: "detail"
        }];

        if (isNonPersonEntityModel(values?.entity)) {
            tabs.push({
                label: "Entities",
                value: "entities"
            });
        }
        if (!!monitoringMacros.length) {
            tabs.push({
                label: "Dashboard",
                value: "dashboard"
            });
        }
        if (values?.entity?.id) {
            tabs.push({
                label: "Positions",
                value: "composition"
            });
            tabs.push({
                label: "Processes",
                value: "processes"
            });
        }
        tabs.push({
            label: "Documents",
            value: "documents"
        });
        if ((values?.entity?.type === "CompanyModel" || values?.entity?.type === "SicavModel") && !!ratings) {
            tabs.push({
                label: "Ratings",
                value: "ratings"
            });
        }

        if (values.entity?.id && !!customScreens && customScreens.length) {
            tabs.push({
                label: "Custom Data",
                value: "customViews"
            });
        }
        tabs.push({
            label: "Theme",
            value: "theming"
        });
        const handleSecuritySelected = (securityId: number) => {
            const security = dictionaries.securities[securityId];
            if (security.type === "CashSummaryModel") {
                navigate("BankAccount", { id: securityId });
            } else if (security.type === "LoanSummaryModel") {
                navigate("Loan", { id: securityId });
            } else if (security.type === "ShareClassSummaryModel" && security.isUnderManagement) {
                navigate("MyShareClass", { id: securityId });
            } else {
                navigate("Security", { id: securityId });
            }
        }
        const extraActionButtons: IActionButton[] = [...extraReportActionButtons, {
            label: "Add Process Execution",
            icon: NotebookCheckOutlineIcon,
            onClick: handleNewExecutionProcess,
            feature: IFeatureModel.EntityDueDiligenceWrite,
        }];

        return <DetailPanel
            isQuerying={entityLoading || compositionDateLoading || customScreenDataLoading || processExecutionsLoading || entitySaving || customScreenDataSubmitting || documentsLoading}
            title={title}
            subTitle={entityCurrent?.type?.replace("Model", "")}
            canSave={dirty && isValid}
            onSaveClick={submitForm}
            canDelete={!!entityCurrent?.id}
            onDeleteClick={handleDelete}
            badge={!entityCurrent?.id ? "new" : undefined}
            tabs={tabs}
            tabValue={tabValue}
            saveAllowed={IFeatureModel.EntityWrite}
            deleteAllowed={IFeatureModel.EntityWrite}
            actions={extraActionButtons}>
            {(tabValue === "detail") && <WriteAccess value={IFeatureModel.EntityWrite}>
                <EntityData
                    personExtensionFields={personExtensionFields}
                    companyExtensionFields={companyExtensionFields}
                    sicavExtensionFields={sicavExtensionFields}
                    formImageFieldName={oProps<IEntityData>().path("imageFile")}
                    formFieldName={oProps<IEntityData>().path("entity")}
                    classificationTypes={classificationTypes}
                    dictionaries={dictionaries}
                    onEntityLoaded={entityAddEntityInDictionary}
                    referenceCountries={referenceCountries} /></WriteAccess>}
            {(tabValue === "ratings" && !!ratings) && <Ratings
                ratingTypes={ratingTypes}
                ratings={ratings} />}
            {(tabValue === "processes") && <ProcessExecutionList
                processExecutions={processExecutions}
                dictionaries={dictionaries} />}
            {(tabValue === "composition" && values && values.entity) && <EntityComposition
                dictionaries={dictionaries}
                composition={composition}
                classificationTypes={securityClassificationTypes}
                classificationTypesFlat={securityClassificationTypesFlat}
                referenceCurrencies={referenceCurrencies}
                pricingDate={currentCompositionDate}
                pricingDates={compositionDates}
                referenceCountries={referenceCountries}
                onTargetSecuritySelected={handleSecuritySelected}
                pricingDatesLoading={compositionDatesLoading}
                onPricingDateChanged={entityDateLoad}
                onVisualizationTypeChanged={entityCompositionVisualizationTypeChange}
                visualizationType={compositionVisualizationType}
                entity={values.entity}
                onSavePosition={entityPositionSave}
                onDeletePosition={handleDeletePosition}
                onSecurityLoaded={entityAddSecurityInDictionary} />}
            {(tabValue === "dashboard" && !!monitoringMacros.length) && <MonitoringResultGroups
                monitoringMacros={monitoringMacros}
                onLoadResult={handleLoadResult}
                resultGroups={monitoringResults} />}
            {(tabValue === "documents") && <ExtendedDocumentsGrid
                documents={documents ?? []}
                dictionaries={dictionaries}
                relatedType={RelatedType.Entity}
                relatedId={values.entity.id} />}
            {(tabValue === "entities") && <EntityList
                dictionaries={dictionaries}
                formFieldName={oProps<IEntityData>().path("entity")}
                onEntityLoaded={entityAddEntityInDictionary}
                onEntitySelected={handleEntitySelected} />}
            {(tabValue === "customViews" && !!customScreenDatas && !!customScreens?.length) && <CustomViewDatas
                canEdit={canEditCustomData}
                dictionaries={dictionaries}
                customScreenLoading={customScreenDataLoading}
                dataSubmitting={customScreenDataSubmitting}
                customScreens={customScreens}
                onDataSubmit={handleDataSubmit}
                customScreenDatas={customScreenDatas} />}
            {(tabValue === "theming") && <EntityWhiteLabelData />}
        </DetailPanel>
    }
}
