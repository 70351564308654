import * as React from "react";
import { useEffect } from "react";
import DetailPanel, { ISubMenuTabs } from "tools/components/DetailPanel";
import { IFeatureModel, IUniverseScopeTypeModel } from "proxy/apiProxy";
import { Formik, FormikHelpers, FormikProps } from 'formik';
import { useGrants, useReduxActions, useReduxSelections } from "tools/lib/reduxStoreAccess";
import SicavData from "./SicavData";
import DetailPanelLoading from "tools/components/DetailPanelLoading";
import { getEntityName } from "tools/lib/modelUtils";
import WriteAccess from "tools/fieldComponents/WriteAccess";
import CustomViewDatas from "features/Relationship/CustomViewDatas";
import useScopeButtonsMenu from "components/global/useScopeButtonsMenu";
import MonitoringResultGroups from "components/global/MonitoringResults";
import { isNumber, oProps, useNumber } from "tools/lib/utility";
import { ISicavData } from "./slice";
import { ProcessExecutionList } from "features/ProcessExecution/ProcessExecutionList";
import NotebookCheckOutlineIcon from "mdi-material-ui/NotebookCheckOutline";
import { IActionButton } from "tools/components/FabContainer";
import { useScreenNavigate, useScreenParams } from "tools/routing/screenRouteHooks";
import EntityList from "features/Entity/EntityList";
import { ExtendedDocumentsGrid, RelatedType } from "features/Document/DocumentsScreen/ExtendedDocumentsGrid";

export const mySicavScreen = {
    route: "/mysicavs/:id/:tab?" as const,
    component: MySicav,
    label: "My Sicav",
    tabs: {
        detail: "details",
        customViews: "customScreens",
        dashboard: "dashboard",
        processes: "processes",
        entities: "entities",
        documents: "documents"
    },
}

function MySicav() {
    const {
        sicavCurrent,
        entityPicture,
        processExecutions,
        documentsLoading,
        documents
    } = useReduxSelections("managedSicav");
    const { managedSicavSave, managedSicavLoad, managedSicavProcessExecutionsLoad, managedSicavDocumentsLoad } = useReduxActions("managedSicav");
    const { macroScriptLoadAll } = useReduxActions("macroScript");

    const { id, tab: tabValue = "detail" } = useScreenParams<typeof mySicavScreen>();
    const idNum = useNumber(id);

    useEffect(() => {
        if (isNumber(idNum)) managedSicavLoad(idNum)
        macroScriptLoadAll()
    }, [idNum, macroScriptLoadAll, managedSicavLoad]);

    useEffect(() => {
        if (isNumber(idNum) && tabValue === "processes" && !processExecutions)
            managedSicavProcessExecutionsLoad(idNum)
    }, [idNum, managedSicavProcessExecutionsLoad, processExecutions, tabValue]);

    useEffect(() => {
        if (isNumber(idNum) && tabValue === "documents" && !documentsLoading && !documents)
            managedSicavDocumentsLoad(idNum)
    }, [idNum, managedSicavDocumentsLoad, documents, documentsLoading, tabValue]);

    const formValues: ISicavData | undefined = React.useMemo(() => {
        if (!sicavCurrent) {
            return undefined;
        }
        const ret: ISicavData = {
            sicav: sicavCurrent,
            imageFile: entityPicture,
        };
        return ret;
    }, [entityPicture, sicavCurrent]);

    const handleSubmit = React.useCallback((values: ISicavData, { setSubmitting }: FormikHelpers<ISicavData>) => {
        managedSicavSave(values);
        setSubmitting(false);
    }, [managedSicavSave])

    if (!formValues) {
        return <DetailPanelLoading tabNumber={2} hasSubTitle={false} />;
    }

    return <Formik onSubmit={handleSubmit} initialValues={formValues} enableReinitialize={true} validateOnMount={true} >
        {formikProps => <SicavForm formikProps={formikProps} />}
    </Formik>;
}
function SicavForm({ formikProps: { dirty, isValid, submitForm, values: formValues } }: { formikProps: FormikProps<ISicavData> }) {
    const {
        sicavCurrent,
        sicavLoading,
        dictionaries,
        sicavSaving,
        customScreenDataLoading,
        customScreenDatas,
        customScreens,
        customScreenDataSubmitting,
        monitoringResults,
        processExecutionsLoading,
        processExecutions,
        documentsLoading,
        documents
    } = useReduxSelections("managedSicav");
    const { referenceCountries = {}, referenceCurrencies = {} } = useReduxSelections("reference");
    const { classificationTypes } = useReduxSelections("entityClassificationType");
    const navigate = useScreenNavigate();
    const {
        managedSicavDelete,
        managedSicavAddEntityInDictionary,
        managedSicavCustomScreenDataSubmit,
        managedSicavMonitoringResultLoad,
    } = useReduxActions("managedSicav");
    const isGranted = useGrants();
    const { tab: tabValue = "detail" } = useScreenParams<typeof mySicavScreen>()

    const extraReportActionButtons = useScopeButtonsMenu(IUniverseScopeTypeModel.Sicav, sicavCurrent?.id);
    const handleLoadResult = React.useCallback((id: number) => formValues && managedSicavMonitoringResultLoad({ id, targetId: formValues.sicav.id }), [formValues, managedSicavMonitoringResultLoad]);
    const { macroScriptList } = useReduxSelections("macroScript");
    const monitoringMacros = React.useMemo(() => macroScriptList.filter(i => (i.type === "MonitoringMacroScriptModel" || i.type === "MonitoringMacroScriptSummaryModel") && i.singleScope === IUniverseScopeTypeModel.Sicav), [macroScriptList]);
    const title = !!formValues?.sicav.id ? (getEntityName(sicavCurrent) ?? "") : 'New entity';
    const handleDelete = React.useCallback(() => {
        if (sicavCurrent?.id) {
            managedSicavDelete(sicavCurrent.id);
        }
    }, [managedSicavDelete, sicavCurrent?.id])
    const handleNewExecutionProcess = React.useCallback(() => {
        navigate("ProcessExecutionNew", {
            type: "EntityProcessExecutionModel",
            targetId: sicavCurrent?.id ?? 0
        });
    }, [sicavCurrent?.id, navigate])

    const handleDataSubmit = React.useCallback((customScreenId: number, values: any) => formValues && managedSicavCustomScreenDataSubmit({ id: formValues.sicav.id, customScreenId, values }), [formValues, managedSicavCustomScreenDataSubmit]);
    const canEditCustomData = isGranted({ feature: IFeatureModel.ManagedPortfolioCustomDataWrite, sicavId: formValues?.sicav.id ?? 0 });
    const tabs: ISubMenuTabs<typeof mySicavScreen>[] = [{
        label: "Data",
        value: "detail"
    }, {
        label: "Entities",
        value: "entities"
    }];
    if (!!monitoringMacros.length) {
        tabs.push({
            label: "Dashboard",
            value: "dashboard"
        });
    }
    if (sicavCurrent?.id) {
        tabs.push({
            label: "Processes",
            value: "processes"
        });
        tabs.push({
            label: "Documents",
            value: "documents"
        });
    }
    if (sicavCurrent?.id && !!customScreens && customScreens.length) {
        tabs.push({
            label: "Custom Data",
            value: "customViews"
        });
    }
    const extraActionButtons: IActionButton[] = [...extraReportActionButtons, {
        label: "Add Process Execution",
        icon: NotebookCheckOutlineIcon,
        onClick: handleNewExecutionProcess,
        feature: IFeatureModel.ManagedPortfolioWrite//,
    }];
    const handleEntitySelected = React.useCallback((entityId: number) => {
        const entity = dictionaries.entities[entityId];
        if (!entity) {
            return;
        }
        switch (entity.type) {
            case "PersonSummaryModel":
                navigate("Person", { id: entityId });
                break;
            case "EntityGroupSummaryModel":
                navigate("EntityGroup", { id: entityId });
                break;
            case "CompanySummaryModel":
                navigate("Company", { id: entityId });
                break;
            case "SicavSummaryModel":
                navigate("Sicav", { id: entityId });
                break;
        }
    }, [dictionaries.entities, navigate]);
    return <WriteAccess value={{ feature: IFeatureModel.ManagedPortfolioWrite, sicavId: formValues?.sicav?.id ?? 0 }}>
        <DetailPanel
            isQuerying={sicavLoading || sicavSaving || customScreenDataLoading || customScreenDataSubmitting || processExecutionsLoading || documentsLoading}
            title={title}
            subTitle={sicavCurrent?.type?.replace("Model", "")}
            canSave={dirty && isValid}
            onSaveClick={submitForm}
            canDelete={!!sicavCurrent?.id}
            onDeleteClick={handleDelete}
            saveAllowed={{ feature: IFeatureModel.ManagedPortfolioWrite, sicavId: formValues?.sicav?.id ?? 0 }}
            deleteAllowed={{ feature: IFeatureModel.ManagedPortfolioWrite, sicavId: formValues?.sicav?.id ?? 0 }}
            badge={!sicavCurrent?.id ? "new" : undefined}
            tabs={tabs}
            tabValue={tabValue}
            actions={extraActionButtons}>
            {(tabValue === "detail") && <SicavData
                classificationTypes={classificationTypes}
                formFieldName={oProps<ISicavData>().path("sicav")}
                formImageFieldName={oProps<ISicavData>().path("imageFile")}
                dictionaries={dictionaries}
                onEntityLoaded={managedSicavAddEntityInDictionary}
                referenceCountries={referenceCountries}
                referenceCurrencies={referenceCurrencies} />}
            {(tabValue === "dashboard" && !!monitoringMacros.length) && <MonitoringResultGroups
                monitoringMacros={monitoringMacros}
                onLoadResult={handleLoadResult}
                resultGroups={monitoringResults} />}
            {(tabValue === "customViews" && !!customScreenDatas && !!customScreens?.length) && <CustomViewDatas
                canEdit={canEditCustomData}
                dictionaries={dictionaries}
                customScreenLoading={customScreenDataLoading}
                dataSubmitting={customScreenDataSubmitting}
                customScreens={customScreens}
                onDataSubmit={handleDataSubmit}
                customScreenDatas={customScreenDatas} />}
            {(tabValue === "entities") && <EntityList
                dictionaries={dictionaries}
                formFieldName={oProps<ISicavData>().path("sicav")}
                onEntityLoaded={managedSicavAddEntityInDictionary}
                onEntitySelected={handleEntitySelected} />}
            {(tabValue === "processes") && <ProcessExecutionList
                processExecutions={processExecutions}
                dictionaries={dictionaries} />}
            {(tabValue === "documents") && <ExtendedDocumentsGrid
                documents={documents ?? []}
                dictionaries={dictionaries}
                relatedType={RelatedType.Entity}
                relatedId={formValues.sicav.id} />}
        </DetailPanel>
    </WriteAccess>
}
