import { merge } from "rxjs";
import { filter, map, mergeMap, share, switchMap, tap } from "rxjs/operators";
import { Epic } from "redux-observable";
import { mapToPayload } from "lib/rxJsUtility";
import { ActionFactories as DocumentActionFactories } from "./slice";
import { documentsApi, IGetDocumentModel } from "proxy/apiProxy";
import { ActionFactories, IAnyAction } from "features";
import saveAs from "file-saver";
import { createNewDocumentModelInstance } from "./utils";

export const loadDocument: Epic<IAnyAction>
    = action$ => {
        const requestedId$ = action$.pipe(
            mapToPayload("document", "documentLoad"),
            share()
        );
        return merge(
            requestedId$.pipe(
                filter(id => !!id),
                mergeMap(payload => documentsApi.getAsync({ id: payload })),
                map(DocumentActionFactories.documentLoaded)),
            requestedId$.pipe(
                filter(id => !id),
                map(createNewGetDocumentModelInstance),
                map(DocumentActionFactories.documentLoaded)));
    };

export const searchDocuments: Epic<IAnyAction>
    = action$ => action$.pipe(
        mapToPayload("document", "documentSearch"),
        mergeMap(documentsApi.searchAsync),
        map(DocumentActionFactories.documentLoadedAll));

export const loadDocuments: Epic<IAnyAction>
    = action$ => action$.pipe(
        mapToPayload("document", "documentLoadAll"),
        mergeMap(() => documentsApi.getAllAsync({})),
        map(DocumentActionFactories.documentLoadedAll));

export const deleteDocument: Epic<IAnyAction>
    = (action$) => {
        const itemDeleted$ = action$.pipe(
            mapToPayload("document", "documentDelete"),
            switchMap(id => documentsApi.deleteAsync({ id }).then(() => id)),
            map(DocumentActionFactories.documentDeleted),
            share());
        return merge(
            itemDeleted$,
            itemDeleted$.pipe(map(() => ActionFactories.navigation.navigationNavigate(undefined))));
    };

function createNewGetDocumentModelInstance(): IGetDocumentModel {
    return {
        document: createNewDocumentModelInstance(),
        entities: {},
        portfolios: {},
        securities: {},
        relationships: {},
        transactions: {},
        cashMovements: {}
    };
}

export const saveDocument: Epic<IAnyAction>
    = action$ => action$.pipe(
        mapToPayload("document", "documentSave"),
        mergeMap(async (data) => {
            const document = await documentsApi.saveAsync({ model: data.document });
            if (data.file) {
                const { content, mimeType, fileName } = data.file;
                if (content) {
                    await documentsApi.saveFileAsync({
                        id: document.id,
                        fileModel: { data: content, mimeType, name: fileName }
                    });
                } else {
                    // FIXME Properly handle empty files with a warning and not brutally reject them
                    console.error(`File ${fileName} has no content. Not saving it.`);
                }
            }
            else if (data.file === null) {
                console.error(`File cannot be deleted`);
            }

            return { ...document, fileName: data.file?.fileName };
        }),
        map(DocumentActionFactories.documentSaved));

export const documentLoadFile: Epic<IAnyAction>
    = action$ => action$.pipe(
        mapToPayload("document", "documentLoadFile"),
        mergeMap(id => documentsApi.getFileAsync({ id })),
        tap(({ blob, fileName }) => saveAs(blob, fileName)),
        map(() => ActionFactories.app.dummy()));
