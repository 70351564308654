import produce from "immer";
import { IGetDocumentModel, IGetDocumentsModel, IDocumentModel, IDocumentSummaryModel, IDocumentsSearchParameters } from "proxy/apiProxy";
import { produceActionFactories, AnyActionOf } from "tools/lib/store";
import { IBase64File } from "tools/lib/utility";
import { GetAllSummaries } from "./utils";

export interface IDocumentData {
    document: IDocumentModel;
    file?: IBase64File | null;
}

export interface IState {
    documentAllLoading: boolean;
    documentLoading: boolean;
    documents: (IDocumentSummaryModel | IDocumentModel)[];
    documentCurrent?: IDocumentModel;
    documentSaving: boolean;
    documentDeleting: boolean;
    dictionaries: GetAllSummaries;
}

export const ActionFactories = produceActionFactories({
    documentSearch: (payload: IDocumentsSearchParameters) => payload,

    documentLoadAll: () => undefined,
    documentLoadedAll: (payload: IGetDocumentsModel) => payload,

    documentLoad: (payload: number) => payload,
    documentLoaded: (payload: IGetDocumentModel) => payload,

    documentLoadFile: (payload: number) => payload,

    documentSave: (payload: IDocumentData) => payload,
    documentSaved: (payload: IDocumentModel) => payload,

    documentDelete: (payload: number) => payload,
    documentDeleted: (payload: number) => payload,

    documentAddRelatedToDictionary: (payload: GetAllSummaries) => payload,
});

export function reducer(
    state: IState = {
        documentAllLoading: false,
        documentLoading: false,
        documents: [],
        documentSaving: false,
        documentDeleting: false,
        dictionaries: {
            entities: {},
            relationships: {},
            portfolios: {},
            securities: {},
            transactions: {},
            cashMovements: {},
        },
    },
    action: AnyActionOf<typeof ActionFactories>
): IState {
    return produce(state, draft => {
        switch (action.type) {
            case "documentSearch":
                draft.documentAllLoading = true;
                break;
            case "documentLoadAll":
                draft.documentAllLoading = true;
                break;
            case "documentLoadedAll":
                draft.documentAllLoading = false;
                draft.documents = action.payload.documents;
                draft.dictionaries.entities = { ...draft.dictionaries.entities, ...action.payload.entities };
                draft.dictionaries.relationships = { ...draft.dictionaries.relationships, ...action.payload.relationships };
                draft.dictionaries.portfolios = { ...draft.dictionaries.portfolios, ...action.payload.portfolios };
                draft.dictionaries.securities = { ...draft.dictionaries.securities, ...action.payload.securities };
                draft.dictionaries.transactions = { ...draft.dictionaries.transactions, ...action.payload.transactions };
                draft.dictionaries.cashMovements = { ...draft.dictionaries.cashMovements, ...action.payload.cashMovements };
                break;

            case "documentLoad":
                draft.documentLoading = true;
                draft.documentCurrent = undefined;
                break;
            case "documentLoaded":
                draft.documentLoading = false;
                draft.documentCurrent = action.payload.document;
                draft.dictionaries.entities = { ...draft.dictionaries.entities, ...action.payload.entities };
                draft.dictionaries.relationships = { ...draft.dictionaries.relationships, ...action.payload.relationships };
                draft.dictionaries.portfolios = { ...draft.dictionaries.portfolios, ...action.payload.portfolios };
                draft.dictionaries.securities = { ...draft.dictionaries.securities, ...action.payload.securities };
                draft.dictionaries.transactions = { ...draft.dictionaries.transactions, ...action.payload.transactions };
                draft.dictionaries.cashMovements = { ...draft.dictionaries.cashMovements, ...action.payload.cashMovements };
                break;

            case "documentSave":
                draft.documentSaving = true;
                break;
            case "documentSaved":
                draft.documentSaving = false;
                const saved = action.payload;
                draft.documentCurrent = saved;
                const existing = draft.documents.find(i => i.id === saved.id);
                if (existing) {
                    Object.assign(existing, saved);
                }
                else {
                    draft.documents.push(saved);
                }
                break;

            case "documentDelete":
                draft.documentDeleting = true;
                break;
            case "documentDeleted":
                draft.documentDeleting = false;
                if (draft.documentCurrent?.id === action.payload) {
                    delete draft.documentCurrent;
                }
                const idx = draft.documents.findIndex(i => i.id === action.payload);
                if (idx >= 0) {
                    draft.documents.splice(idx, 1);
                }
                break;

            case "documentAddRelatedToDictionary":
                draft.dictionaries.entities = { ...draft.dictionaries.entities, ...action.payload.entities };
                draft.dictionaries.relationships = { ...draft.dictionaries.relationships, ...action.payload.relationships };
                draft.dictionaries.portfolios = { ...draft.dictionaries.portfolios, ...action.payload.portfolios };
                draft.dictionaries.securities = { ...draft.dictionaries.securities, ...action.payload.securities };
                draft.dictionaries.transactions = { ...draft.dictionaries.transactions, ...action.payload.transactions };
                draft.dictionaries.cashMovements = { ...draft.dictionaries.cashMovements, ...action.payload.cashMovements };
                break;
        }
    });
}
