import produce from "immer";
import { produceActionFactories, AnyActionOf } from "tools/lib/store";
import { ICashMovementModel, IGetCashMovementsModel, SecuritySummaryModel, IGetCashMovementModel, IBankAccountSummaryModel, ISubFundSummaryModel, IDocumentSummaryModel, IGetDocumentsModel } from "proxy/apiProxy";
import { ICashMovementsSearchParameters } from "proxy/apiProxy";
import { IGetBankAccountSummary, IGetPortfoliosSummary, IGetPortfolioSummary, IGetEntitySummary, IGetSecuritySummary } from "features/Reference/slice";
import { GetAllSummaries } from "features/Document/utils";

export const ActionFactories = produceActionFactories({
    cashMovementsSearch: (payload: ICashMovementsSearchParameters) => payload,
    cashMovementsLoadedAll: (payload: IGetCashMovementsModel) => payload,
    cashMovementsAddPortfoliosToDictionary: (payload: IGetPortfoliosSummary) => payload,

    cashMovementLoad: (payload: number) => payload,
    cashMovementLoaded: (payload: IGetCashMovementModel) => payload,
    cashMovementSave: (payload: ICashMovementModel) => payload,
    cashMovementSaved: (payload: ICashMovementModel) => payload,
    cashMovementDelete: (payload: number) => payload,
    cashMovementDeleted: (payload: number) => payload,

    cashMovementEntitySelected: (payload: IGetEntitySummary) => payload,
    cashMovementPortfolioSelected: (payload: IGetPortfolioSummary) => payload,
    cashMovementSecuritySelected: (payload: IGetSecuritySummary) => payload,
    cashMovementBankAccountSelected: (payload: IGetBankAccountSummary) => payload,

    cashMovementDocumentsLoad: (payload: number) => payload,
    cashMovementDocumentsLoaded: (payload: IGetDocumentsModel) => payload,
});

export interface IState {
    cashMovementsLoading: boolean;
    cashMovements: ICashMovementModel[];

    cashMovementCurrent?: ICashMovementModel;
    cashMovementLoading: boolean;
    cashMovementSaving: boolean;
    cashMovementDeleting: boolean;

    dictionaries: GetAllSummaries & {
        subFunds: Record<string | number, ISubFundSummaryModel>,
        securities: Record<string | number, SecuritySummaryModel>,
        bankAccounts: Record<string | number, IBankAccountSummaryModel>,
    },

    documents?: IDocumentSummaryModel[];
    documentsLoading: boolean;
}

export function reducer(
    state: IState = {
        cashMovementsLoading: false,
        cashMovementLoading: false,
        cashMovementSaving: false,
        cashMovementDeleting: false,
        cashMovements: [],
        dictionaries: {
            portfolios: {},
            subFunds: {},
            securities: {},
            entities: {},
            bankAccounts: {},
            relationships: {},
            cashMovements: {},
            transactions: {},
        },
        documentsLoading: false,
    },
    action: AnyActionOf<typeof ActionFactories>
): IState {
    return produce(state, draft => {
        switch (action.type) {
            case "cashMovementDocumentsLoad":
                draft.documentsLoading = true;
                break;
            case "cashMovementDocumentsLoaded":
                draft.documentsLoading = false;
                draft.documents = action.payload.documents;
                draft.dictionaries.entities = { ...draft.dictionaries.entities, ...action.payload.entities };
                draft.dictionaries.securities = { ...draft.dictionaries.securities, ...action.payload.securities };
                draft.dictionaries.portfolios = { ...draft.dictionaries.portfolios, ...action.payload.portfolios };
                draft.dictionaries.relationships = { ...draft.dictionaries.relationships, ...action.payload.relationships };
                draft.dictionaries.cashMovements = { ...draft.dictionaries.cashMovements, ...action.payload.cashMovements };
                draft.dictionaries.transactions = { ...draft.dictionaries.transactions, ...action.payload.transactions };
                break;
            case "cashMovementEntitySelected":
                draft.dictionaries.entities[action.payload.entity.id] = action.payload.entity;
                draft.dictionaries.entities = { ...draft.dictionaries.entities, ...action.payload.entities };
                break;
            case "cashMovementPortfolioSelected":
                draft.dictionaries.portfolios[action.payload.portfolio.id] = action.payload.portfolio;
                draft.dictionaries.entities = { ...draft.dictionaries.entities, ...action.payload.entities };
                break;
            case "cashMovementSecuritySelected":
                draft.dictionaries.securities[action.payload.security.id] = action.payload.security;
                draft.dictionaries.subFunds = { ...draft.dictionaries.subFunds, ...action.payload.subFunds };
                draft.dictionaries.entities = { ...draft.dictionaries.entities, ...action.payload.entities };
                break;
            case "cashMovementBankAccountSelected":
                draft.dictionaries.bankAccounts[action.payload.bankAccount.id] = action.payload.bankAccount;
                draft.dictionaries.entities = { ...draft.dictionaries.entities, ...action.payload.entities };
                break;
            case "cashMovementsSearch":
                draft.cashMovementsLoading = true;
                break;
            case "cashMovementsLoadedAll":
                draft.cashMovementsLoading = false;
                const { cashMovements, ...dictionaries } = action.payload;
                draft.dictionaries = { ...draft.dictionaries, ...dictionaries };
                draft.cashMovements = cashMovements;
                break;
            case "cashMovementsAddPortfoliosToDictionary":
                draft.dictionaries.entities = { ...draft.dictionaries.entities, ...action.payload.entities };
                draft.dictionaries.portfolios = { ...draft.dictionaries.portfolios, ...action.payload.portfolios };
                break;
            case "cashMovementDelete":
                draft.cashMovementDeleting = true;
                break;
            case "cashMovementDeleted":
                draft.cashMovementDeleting = false;
                const deletedId = action.payload;
                if (draft.cashMovementCurrent?.id === deletedId) {
                    delete draft.cashMovementCurrent;
                }
                const idx = draft.cashMovements.findIndex(i => i.id === deletedId);
                if (idx >= 0) {
                    draft.cashMovements.splice(idx, 1);
                }
                break;
            case "cashMovementLoad":
                draft.cashMovementLoading = true;
                draft.cashMovementCurrent = undefined;
                draft.documents = undefined;
                break;
            case "cashMovementLoaded":
                draft.cashMovementLoading = false;
                draft.cashMovementCurrent = action.payload.cashMovement;
                draft.dictionaries.entities = { ...draft.dictionaries.entities, ...action.payload.entities };
                draft.dictionaries.portfolios = { ...draft.dictionaries.portfolios, ...action.payload.portfolios };
                draft.dictionaries.subFunds = { ...draft.dictionaries.subFunds, ...action.payload.subFunds };
                draft.dictionaries.securities = { ...draft.dictionaries.securities, ...action.payload.securities };
                draft.dictionaries.bankAccounts = { ...draft.dictionaries.bankAccounts, ...action.payload.bankAccounts };
                break;
            case "cashMovementSave":
                draft.cashMovementSaving = true;
                break;
            case "cashMovementSaved":
                draft.cashMovementSaving = false;
                const saved = action.payload;
                draft.cashMovementCurrent = saved;
                const existing = draft.cashMovements.find(i => i.id === saved.id);
                if (existing) {
                    Object.assign(existing, saved);
                }
                else {
                    draft.cashMovements.push(saved);
                }
                break;
        }
    });
}
